import React from 'react'
import Layout from '../components/layout'
import { Link, navigate } from 'gatsby'

export default function DriverCode () {
  return (
    <Layout type='driver'>
      <section className='story'>
        <div className='row align-center'>
          <div className='col'>
            <div className="inner">
              <h1>Driver code of conduct</h1>

              <div className="article-body">
                <h2>Driver regulations &amp; tips</h2>
                <p>Always make sure that your vehicle is clean and uncluttered. When you pick up a delivery, it’s important that you have ample space in your vehicle so the item is not damaged, squeezed or crushed to fit.</p>
                <p>It is Trexity regulation that there is absolutely no animals, smoking or chemicals in the vehicle during a delivery request.</p>
                <p>Please make sure that if you are provided a Trexity thermal insulated bag, that it is cleaned regularly and sanitized on a daily basis. Driver checks will occur on behalf of the Trexity street team.</p>
                <p>Only accept a delivery request if you are able to pick it up, merchant’s don’t like getting accepted and then waiting around for when you might have time to help them out. Accept, pickup right away, and earn money.</p>
                <p>Since our driver community is growing everyday and we have lots of drivers who want to deliver, make sure you pickup a request right away to be fair to your other drivers. If you are too busy, then let another driver accept it.</p>
                <p>Don’t accept multiple requests and hoard them in your driver app. Only accept packages that you can and will pickup in a timely manner.</p>
                <p>Always wear a smile, it looks good on you!</p>

                <h2>How to work with merchants</h2>
                <p>Do your best to help our merchants in any way you can, be courteous, respectful, helpful and willing to do whatever it takes to provide the best possible experience. The happier they are, the more deliveries you’ll have the opportunity to move.</p>
                <p>If you have questions about the delivery notes, always ask them upon pickup. Communication with the merchant will <em>always</em> minimize errors and provide them with confidence in you and Trexity.</p>
                <p>Don’t be afraid to call them from the Trexity Driver App if you have a problem, never guess what to do, always ask the merchant.</p>
                <p>Never deliver an item if something feels wrong or off, contact the merchant or Trexity and we’ll be there to help you! You are never alone, we work as a team.</p>
                <p>Never argue with our merchants, if there is a dispute of any kind, you can contact Trexity right away and we’ll be there to support you. We love our drivers, we’re all in this together.</p>

                <h2>Making your deliveries</h2>
                <p>Rely on your Driver Application to make sure that you are going to the correct delivery address, Google Maps is integrated into each delivery address.</p>
                <p>Follow the delivery notes in the app, and always use your best judgement.  What if that package was for you? How would you want it delivered?</p>
                <p>If the person receiving the delivery has an issue with the item, always have them contact the merchant right away.</p>
                <p>If the item gets damaged or spoiled while in your possession, always contact the merchant and Trexity to let them know. It’s nothing to worry about, we are here to help you and fix the problem.</p>
                <p>If you ever feel unsafe about a delivery or where the package is being asked to get delivered, contact Trexity right away and tell the merchant.</p>
                <p>Always make sure to take a clear image of your delivery so we can make sure that you did the best you could, and leave notes in the driver app when it prompts you if you have something to tell us.</p>
                <p>If buzz code information is not provided, and you cannot find the recipient in the building directory, call the recipient through the Driver App. If there is no answer, contact Trexity support through the app for further instructions.</p>

                <h2>That’s it.</h2>
                <p>If you have any questions, we’ll be happy to answer them. You can contact us directly through the driver app or on our <Link to='/contact'>contact page</Link>.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
